var $ = require('./jquery.js').default;
const $navbar = $('#menu');

module.exports = function(opt) {
    $('a[href^="#"]').on('click', function(e) {
        e.preventDefault();
        var href = $(this).attr('href');
        if (href === '#') {
            return true;
        }
        const scrollTop = $(href).position().top - $navbar.outerHeight();
        $('html, body').animate({ scrollTop });

        if (opt && typeof opt.onScroll === 'function') {
            opt.onScroll();
        }
    });
};
