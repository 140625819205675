var $ = require('./jquery.js').default;

module.exports = function() {
    $('.card-wrapper').hover(
        function() {
            $(this).find('.question-popup').show();
        },
        function() {
            $(this).find('.question-popup').hide();
        }
    );

    $('.card-wrapper').click(function() {
        $(this).find('.js-popup-overlay').show();
    });

    $('.js-card-popup-close-btn').click(function () {
        event.stopPropagation();
        event.preventDefault();
        $(this).parent().parent().hide();
        return false;
    });
}
/*
  var popupBtnEls = document.getElementsByClassName('js-card-popup-btn');

  if (popupBtnEls) {
  Array.from(popupBtnEls).forEach(function(el) {
  el.addEventListener('click', function(event) {
  event.stopPropagation();
  event.preventDefault();
  var clickedEl = event.target;
  if (!clickedEl) return;
  var cardWrapper = clickedEl.tagName === 'DIV' ? clickedEl.parentElement : clickedEl.parentElement.parentElement;
  if (!cardWrapper) return;
  var popupEl = cardWrapper.getElementsByClassName('js-popup-overlay');
  if (!popupEl || !popupEl[0]) return;
  popupEl[0].style.setProperty('display', 'block', 'important');
  });
  });
  }

  var closeBtnEls = document.getElementsByClassName('js-card-popup-close-btn');

  if (closeBtnEls) {
  Array.from(closeBtnEls).forEach(function(el) {
  el.addEventListener('click', function(event) {
  event.stopPropagation();
  event.preventDefault();
  var clickedEl = event.target;
  if (!clickedEl) return;
  var cardWrapper = clickedEl.parentElement.parentElement;
  if (!cardWrapper) return;
  cardWrapper.style.setProperty('display', 'none', 'important');
  });
  });
  }
*/
