var hamburger = require('./hamburger.js');
var scrollto = require('./scrollto.js');
var cards = require('./cards.js');
var sticky = require('./sticky.js');

$(document).ready(function() {
    hamburger.init();
    scrollto({ onScroll: function() {
        hamburger.close.apply(hamburger);
    }});
    cards();
    sticky();
});
