var $ = require('./jquery.js').default;

var isVisible = false;
var $hamburgerButton;
var $hamburgerMenu;

var close = function() {
    if ($hamburgerMenu) {
        $hamburgerMenu.hide('fast');
        isVisible = false;
    }
}

var init = function() {
    $hamburgerButton = $('#hamburger-button');
    $hamburgerMenu = $('#hamburger-menu');

    $hamburgerButton.on('click', function(e) {
        e.preventDefault();
        if (isVisible) {
            close();
        } else {
            $hamburgerMenu.show('fast');
            isVisible = true;
        }
    });
};


module.exports.init = init;
module.exports.close = close;
