var $ = require('./jquery.js').default;

module.exports = function() {
    var menu = document.getElementById('menu');
    var header = document.getElementById('header');
    var topBar = document.getElementById('top-bar');
    var fill = document.getElementById('sticked-fill');
    var $header = $('#header');
    var $topbar = $('#top-bar');
    var stickedContainer = document.getElementById('sticked-container');
    var $stickedContainer = $('#sticked-container');
    var topBarHeight = topBar.offsetHeight;
    var menuHeight = menu.offsetHeight;
    var headerHeight = header.offsetHeight;
    var headerTop = header.offsetTop;

    stickedContainer.classList.add('sticked');
    fill.style.height = stickedContainer.offsetHeight + 'px';

    window.addEventListener('scroll', function() {
        // if (window.scrollY >= header.offsetTop) {
        if (window.scrollY >= 95) {
            stickedContainer.classList.add('scrolled');
        } else {
            stickedContainer.classList.remove('scrolled');
        }
        var headerOffsetBottom = $stickedContainer.offset().top - $header.height();
        var topbarHeight = $topbar.height();
        var f = 0;
        if (headerOffsetBottom > 0) {
            f = (headerOffsetBottom <= topbarHeight) ? headerOffsetBottom : topbarHeight;
        } else {
            f = 0;
        }
        stickedContainer.style.top = (-1 * f) + 'px';
    });
};
